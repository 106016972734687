/* src/pages/Contact.css */

.contact-icons {
  display: flex;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 10px 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
 
  gap: 50px;
}
.contact-icons2 {
  display: flex;
  position: absolute;
  top: 44%;
  left: 78%;
  padding: 10px 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  gap: 50px;
}



@media (max-width: 768px) {
  .contact-icons2 {
    top: 91%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%; /* מקטין את הרוחב כדי שיתאים למסכים קטנים יותר */
    gap: 20px;
    padding: 10px 0;
  }
}

.contact-icons a {
  font-size: 24px;
  color: #010508;
  text-decoration: none;
  
}

.tele-img {
  width: 180px;
  height: auto;
}


.contact-icon {
  margin: 0 10px;
  color: #000;
}

.contact-icon:hover {
  animation: tick-tock 0.4s backwards;
  transform-origin: bottom;
}

@keyframes tick-tock {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
}
@media (min-width: 769px) and (max-width: 1024px) {

  .contact-icons2 {
    top: 200px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%; /* מקטין את הרוחב כדי שיתאים למסכים קטנים יותר */
    gap: 20px;
    padding: 10px 0;
  }

}