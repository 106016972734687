@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

@font-face {
  font-family: 'Abraham';
  src: url('../font/Abraham/Abraham-Regular.eot') format('eot'),
       url('../font/Abraham/Abraham-Regular.ttf') format('ttf'),
       url('../font/Abraham/Abraham-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gan';
  src: url('../font/gan/ganclm_bold-webfont.ttf') format('ttf'),
       url('../font/gan/ganclm_bold-webfont.woff') format('woff');
         font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Thaoma';
  src: url('../font/Thaoma/tahoma.ttf') format('ttf');
         font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gisha';
  src: url('../font/Gisha/gisha.ttf') format('ttf');
  src: url('../font/Gisha/gisha-2.woff') format('woff');
         font-weight: normal;
  font-style: normal;
}


body {
  font-family: 'Abraham', sans-serif;
  font-family: 'Gan', sans-serif;
  font-family: 'Thaoma', sans-serif;
  font-family: 'Gisha', sans-serif;
}


html {
  margin: 0;
  padding: 0;
  height: 100vh; 
  width: 100%;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  font-family: sans-serif;
  position: relative;
  background-image: url('/public/backgr123.jpg');
  
}
body {
  margin: 0;
  padding: 0;
  height: 100vh; 
  width: 100%;
  background-color: rgba(255, 255, 255, 0.633);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  font-family: sans-serif;
  background-color: none;
  
}


.container {
  max-width: 960px; 
  margin: 0 auto; 
  padding: 20px;
}

.App {
  text-align: right;
  direction: rtl;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  background-color: transparent;
}

header {
  position: relative;
  display: flex;
  background-color: transparent;
  padding: 20px;
  color: #333;
  
}

.Home header {
  display: none;
}

.home {
  position: relative;
  height: 100vh; 
  width: 100%;
  background-image: url('/public/backgr.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat; 
  background-repeat: repeat; 
}



@media (max-width: 768px) {
  html {
    margin: 0;
    padding: 0;
    height: 100vh; 
    width: 100%;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    font-family: sans-serif;
    position: relative;
    background-image: url('/public/bgIMG3.png');
    
  }
  .home {
    background-image: url('/public/bgIMG3.png');
    height: 800px; 
    width: 100%;
    background-size: cover; /* מכסה את כל השטח עם חיתוך אם נדרש */
    background-position: center; /* מוודא שהתמונה ממורכזת */
    background-repeat: no-repeat;
    background-attachment: fixed; /* מונע גלילה של הרקע אם רצוי */
  }
  body {
    margin: 0;
    padding: 0;
    height: 100vh; 
    width: 100%;
    background-color: rgba(255, 255, 255, 0.633);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    font-family: sans-serif;
    background-color: none;
  }
  
}


.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}




.content {
  flex: 1;
}

.icon-bar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.icon-bar li {
  margin: 0 10px;
}

.icon-bar li a img {
  width: 200px;
  height: auto;
}

.icon-bar li a img:hover {
  transform: scale(1.1);
}

.nav-links1, .nav-links2 {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links {
  justify-content: center; /* מרכז אופקית */
  align-items: center; /* מרכז אנכית */
  gap: 10px;
  padding: 0;
  margin: 20px 0 10px;
  margin-top: 60px;

}






.nav-links li, .nav-links1 li, .nav-links2 li {
  margin: 0 10px;
}

.nav-links li img, .nav-links2 {
  width: 110px;
  height: auto;
  transition: transform 0.3s ease;
}


.nav-links li img:hover, .nav-linkss li img:hover, .nav-links2 .pic2 img:hover {
    animation: tick-tock 0.4s backwards; /* קבע את זמן האנימציה ואת מספר החזרות */
  transform-origin: bottom; /* הגדר את נקודת הסיבוב */
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

nav ul li {
  margin: 0 10px;
}

nav ul li img {
  width: 100px;
  height: 90px;
}

/* src/components/Footer.css */

.footer {
  background-color: #ccf9c1;
  color: #020000;
  text-align: center;
  position: relative;
  width: 100%;
  
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer p {
  margin: 2px 0;
  font-size: 10px; 
  font-family: "Arial", "Rubik", sans-serif;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

html, body {
  height: 100%;
  margin: 0;
  overflow: auto; /* משאיר את המסך מקובע */
}
main {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  

}

.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.profile-image img {
  max-width: 50%;
  border-radius: 8px;
}

section {
  max-width: 1000px;
  width: 100%;
  height: 90vh; /* התאמת הגובה לפי הצורך */
  margin: 0 auto 20px; /* הוספת מרווח תחתון של 20px */
  padding: 0px 30px 20px; /* padding מותאם אישית */
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 6px solid #090907;
  overflow-y: scroll; /* שינוי ל-scroll אם רוצים פס גלילה תמידי */
  box-sizing: border-box; /* כולל padding ו-border בתוך הגובה הכולל */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* יישור התוכן להתחלה של ה-section */
}


/* עיצוב פס גלילה - Chrome, Edge, Safari */
section::-webkit-scrollbar {
  width: 12px; /* רוחב הפס גלילה */
}

section::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* צבע הרקע של מסלול הגלילה */
  border-radius: 10px; /* עיגול הפינות של מסלול הגלילה */
}

section::-webkit-scrollbar-thumb {
  background: #090907; /* צבע פס הגלילה */
  border-radius: 10px; /* עיגול הפינות של פס הגלילה */
}

/* עיצוב פס גלילה - Firefox */
section {
  scrollbar-width: thin; /* עיצוב דק של פס הגלילה */
  scrollbar-color: #090907 rgba(0, 0, 0, 0.1); /* צבע פס הגלילה וצבע הרקע של המסלול */
}

.line {
  display: block; /* כל span יהפוך לשורה נפרדת */
}









section h1  {
  font-size: 3em;
  margin-bottom: 0%;
  color: #040303;
  text-shadow: 2px 2px 4px #f2f8f2;
  text-align: center;
  font-family: 'Gan', sans-serif;
  font-weight: bold;
}
section h2  {
  font-size: 1.5em;
  margin-bottom: 0%;
  color: #040303;
  text-shadow: 2px 2px 4px #f2f8f2;
  text-align: center;
  font-family: 'Gan', sans-serif;
  font-weight: bold;
}

section p {
  line-height: 1.2;
  margin-bottom: 0%;
  color: #333;
  font-size: 1.2em;
  font-family: 'Gisha', sans-serif;
  line-height: 1.6; /* קובע את גובה השורה ל-1.6 מהגודל הרגיל */

}
.underlined-text {
  text-decoration: underline; /* קו מתחת לטקסט */
}
form div {
  margin-bottom: 20px;
}

form label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #333;
}

form input, form textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
}

form button {
  background-color: #00b7ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.2em;
}

form button:hover {
  background-color: #005eff;
}

.tele-link {
  display: inline-block;
}


.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 5px;
  justify-content: center;
}

.gallery img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border: 5px solid #050504;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;

}
.indented {
  margin-right: 20px; /* או כל ערך אחר שתרצה */
}
.nav-linkss {
  position: absolute; /* קביעת המיקום של האלמנט */
  top: 82.5%; /* מרחק מהתחתית */
  left: 50%; /* מיקום מרכזי */
  transform: translateX(-50%); /* הזזה מהמרכז */
  display: flex; /* תצוגה של האלמנטים כג'נרלי */
  justify-content: center; /* יישור למרכז */
  align-items: center; /* יישור למרכז */
  width: 98%; /* רוחב מלא */
  background-color: #accff0; /* רקע כחול */
  padding: 0px; /* ריווח פנימי */
  border-radius: 10px 10px 0 0; /* פינות מעוגלות בחלק העליון */
  z-index: 1000; /* עומק השקיפות של האלמנט */
}


.nav-linkss .pic img {
  width: 100%; /* רוחב האלמנט */
  height: auto; /* גובה האלמנט */
  max-width: 120px; /* מיקום האלמנט */
  max-height: 120px; /* גובה מיקום האלמנט */
  object-fit: cover; /* הצגה כמו שה אייקונים */
}

.nav-linkss .pic {
  width: 150px; /* רוחב האלמנט */
  height: 100px; /* גובה האלמנט */
  display: flex; /* תצוגה כג'נרלי */
  justify-content: center; /* יישור למרכז */
  align-items: center; /* יישור למרכז */
  margin: 10px; /* ריווח בין האייקונים */
}




@keyframes tick-tock {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

/* החל את האנימציה על הלוגו בעת ריחוף */
.pic:hover {
  animation: tick-tock 0.4s backwards; /* קבע את זמן האנימציה ואת מספר החזרות */
  transform-origin: bottom; /* הגדר את נקודת הסיבוב */
}



.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;


}

.Modal img {
  max-width: 600px; /* גודל מקסימלי של התמונה */
  max-height: 600px; /* גובה מקסימלי של התמונה */
  margin: 0 auto;
  border-radius: 50px; /* חריץ מעוגל לתמונה */
  transition: transform 0.3s ease-in-out; /* אפקט חלק לתמונה */
}




.Modal button {
  color: rgb(6, 5, 5);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  
}
.modal-buttons {
  display: flex;
  justify-content: center; /* מרכז את האלמנטים באופק */

}
.modal-button {
  background-color: #97d6f1; /* צבע רקע */
  color: white; /* צבע טקסט */
  border: none; /* ללא מסגרת */
  padding: 10px 20px; /* רווח בתוך הכפתור */
  text-align: center; /* מרכוז את הטקסט בכפתור */
  text-decoration: none; /* ביטול הדגשת קישור */
  display: inline-block; /* תצוגה בלבד של כפתור */
  font-size: 16px; /* גודל טקסט */
  margin: 4px 2px; /* רווח בין הכפתורים */
  cursor: pointer; /* סמל ידית */
  border-radius: 8px; /* חריץ מעוגל */
  width: 200px; /* רוחב מותר */
}

.modal-button:hover {
  background-color: #2ea9ef; /* צבע רקע בעקיפה */
}

.button-icon {
  margin-right: 8px; /* רווח בין סמל הכפתור לטקסט */
}


.Modal-close-button {
  color: rgb(6, 5, 5);
  background: transparent;
  border: none;
  font-size: large;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  position: absolute;
  left: 5px;
  top: 5px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.video-container, .video-container1 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.video-container iframe, .video-container1 iframe {
  flex: 1 1 250px;
  max-width: 250px;
  height: 200px;
}

.video-container1 iframe {
  flex: 1 1 550px;
  max-width: 500px;
  height: 300px;
}


/* App.css */
.hamburger-menu {
  text-align: right;
  margin: 10px;
  display: none; /* Hide by default */
}
.hamburger-button {
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
  color: #040404;
  position: fixed;
  top: 120px;
  right: 20px; /* הוספת ערך ל-right כדי להצמיד את הכפתור לצד ימין */
}



.hamburger-button:hover {
  color: #575757; /* צבע כפתור עוד ב צבע */
}

.mobile-logo1 {
  display: none;
  width: 60%;
  text-align: center;
  position: fixed;
  top: 10px; /* מיקום מעט למטה */
  left: 0;
  z-index: 1; /* נמוך יותר מהתפריט הצדדי */
}

.side-menu {
  position: fixed;
  top: 0;
  right: -250px; /* התפריט מוסתר בהתחלה */
  width: 250px;
  height: 100%; /* גובה מלא */
  background-color: #a6efd9;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000; /* לוודא שהתפריט יהיה מעל שאר האלמנטים */
}

.side-menu.open {
  right: 0; /* כשהתפריט פתוח */
}

.overlay {
  display: none; /* מוסתר בהתחלה */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* צבע חצי שקוף */
  z-index: 999; /* נמוך יותר מהתפריט, אך מעל שאר האלמנטים */
}

/* כאשר התפריט פתוח, ה-overlay יופיע */
.side-menu.open + .overlay {
  display: block;
}


.side-menu .close-button {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 36px;
  background: none;
  border: none;
  color: #020101;
  cursor: pointer;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
}

.side-menu ul li {
  padding: 8px 16px;
  text-align: center;
}

.side-menu ul li a {
  font-family: 'Gan', sans-serif;

  color: #020101;
  display: block;
  transition: 0.3s;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px;
}

.side-menu ul li a:hover {
  background-color: #575757;
}

/* Media Queries */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* Show on small screens */
  }

  .side-menu {
    display: block; /* Ensure side menu is considered */
  }
}

@media (max-width: 768px) {
  section {
    max-width: 1000px;
    width: 100%;
    height: 90vh; /* התאמת הגובה לפי הצורך */
    margin: 60px auto 20px; /* הוספת מרווח תחתון של 20px */
    padding: 0px 30px 20px; /* padding מותאם אישית */
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 6px solid #090907;
    overflow-y: scroll; /* שינוי ל-scroll אם רוצים פס גלילה תמידי */
    box-sizing: border-box; /* כולל padding ו-border בתוך הגובה הכולל */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* יישור התוכן להתחלה של ה-section */
  }
  section p {
    line-height: 1.2;
    margin-bottom: 0%;
    color: #333;
    font-size: 1.2em;
    font-family: 'Gisha', sans-serif;
    line-height: 1.6; /* קובע את גובה השורה ל-1.6 מהגודל הרגיל */
  
  }
}

@media (max-width: 768px) {
.profile-image img {
  max-width: 90%;
  border-radius: 8px;
}
}

@media (max-width: 768px) {
  .contact-icons {
    display: flex; /* שימוש ב-Flexbox */
    flex-direction: row; /* הצג בשורה */
    justify-content: center; /* מרכז את האייקונים באמצע */
    align-items: center; /* מרכז את האייקונים אנכית */
    width: 100%; /* רוחב מלא של האלמנט */
    position: absolute; /* מיקום קבוע באופן יחסי לתוכן העמוד */
    top:88%; /* מרווח תחתון של 10% מגובה העמוד */
  }


  .nav-links1,
  .nav-links2,
  .nav-linkss,
  .nav-links {
    display: none; /* הסתר את הרשימה הרגילה */
  }

  .nav-dropdown::after {
    content: '▼';
    font-size: 12px;
    color: #555;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
@media (max-width: 768px) {
.App {
  text-align: right;
  direction: rtl;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  background-color: transparent;
}

}
@media (max-width: 768px) {

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .modal-button {
    margin: 0 5px;
  }

  .Modal {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .Overlay {
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


@media (max-width: 768px) { /* התנאי למסך קטן כמו פלאפון */
  .nav-linkss {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* מרכז את האייקונים בשתי שורות */
    /* background-color: rgba(255, 255, 255, 0.384); */
    background-color: transparent;
    top: 80px;
  }
  .nav-linkss li {
    max-width: 25%; /* חצי מרחב שוליים לכל תמונה */
    text-align: center; /* מרכז את התמונה באופן אנכי */
  }
}



@media (min-width: 769px) {
  .nav-linkss .pic:nth-child(1) { /* בחר את הלוגו הראשון (מי אני) */
    display: none; /* הסתר את הלוגו במסך גדול */
  }
}

@media (max-width: 768px) {
  .nav-linkss .pic:nth-child(1) { /* מי אני */
    order: 1;
  }
  .nav-linkss .pic:nth-child(2) { /* ימי הולדת */
    order: 2;
  }
  .nav-linkss .pic:nth-child(3) { /* בריתות ואירועים */
    order: 3;
  }
  .nav-linkss .pic:nth-child(4) { /* ימי הולדת בנות */
    order: 4;
  }
  .nav-linkss .pic:nth-child(5) { /* איפור פנים */
    order: 5;
  }
  .nav-linkss .pic:nth-child(6) { /* בלונים */
    order: 6;
  }
  .nav-linkss .pic:nth-child(7) { /* גלריה */
    order: 7;
  }
  .nav-linkss .pic:nth-child(8) { /* המלצות */
    order: 8;
  }
  .nav-linkss .pic:nth-child(9) { /* אירועים מהעבר */
    order: 9;
  }
}

@media (max-width: 768px) { /* ניתן לשנות את ערכי המספר באופן זמני */
  .contact-icons {
    display: none; /* הסתרת חלק זה באם רוב האייקונים במסך קטן */
  }
}
/* עיצוב למסך קטן כמו מכשירים ניידים */
@media (max-width: 768px) {

  .footer {
    background-color: #ccf9c1;
    color: #020000;
    text-align: center;
    position: relative;
    bottom: 0px;
    width: 100%;
  }
  
}
@media (max-width: 768px) {
  .gallery {
    display: contents;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .gallery img {
    width: 89%;
    height: auto;
    cursor: pointer;
  }

  .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
  }

  .Modal img  {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }


}

@media (min-width: 769px) and (max-width: 1024px) {

  html {
    margin: 0;
    padding: 0;
    height: 100vh; 
    width: 100%;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    font-family: sans-serif;
    position: relative;
    background-image: url('/public/bgIMG3.png');
    
  }
  .home {
    background-image: url('/public/bgIMG3.png');
    height: 1500px; 
    width: 100%;
    background-size: cover; /* מכסה את כל השטח עם חיתוך אם נדרש */
    background-position: center; /* מוודא שהתמונה ממורכזת */
    background-repeat: no-repeat;
    background-attachment: fixed; /* מונע גלילה של הרקע אם רצוי */
  }
  body {
    margin: 0;
    padding: 0;
    height: 100vh; 
    width: 100%;
    background-color: rgba(255, 255, 255, 0.633);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    font-family: sans-serif;
    background-color: none;
  }
  .nav-linkss {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* מרכז את האייקונים בשתי שורות */
    /* background-color: rgba(255, 255, 255, 0.384); */
    background-color: transparent;
    top: 300px;
  }
  .nav-links1, .nav-links2 {
    display: none !important;
  }
  .nav-linkss li {
    max-width: 25%; /* חצי מרחב שוליים לכל תמונה */
    text-align: center; /* מרכז את התמונה באופן אנכי */
  }

  .footer {
    background-color: #ccf9c1;
    color: #020000;
    text-align: center;
    position: relative;
    top: 0px;
    width: 100%;
  }
  .hamburger-menu {
    display: block; /* Show on small screens */
  }

  .hamburger-button {
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    color: #040404;
    position: fixed;
    top: 220px;
    right: 50px; /* הוספת ערך ל-right כדי להצמיד את הכפתור לצד ימין */
  }
  

  .side-menu {
    display: block; /* Ensure side menu is considered */
  }

  header {
    display: none !important;
  }
  section {
    max-width: 1000px;
    width: 100%;
    height: 90vh; /* התאמת הגובה לפי הצורך */
    margin: 180px auto 20px; /* הוספת מרווח תחתון של 20px */
    padding: 0px 30px 20px; /* padding מותאם אישית */
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 6px solid #090907;
    overflow-y: scroll; /* שינוי ל-scroll אם רוצים פס גלילה תמידי */
    box-sizing: border-box; /* כולל padding ו-border בתוך הגובה הכולל */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* יישור התוכן להתחלה של ה-section */
  }
  section p {
    line-height: 1.2;
    margin-bottom: 0%;
    color: #333;
    font-size: 1.2em;
    font-family: 'Gisha', sans-serif;
    line-height: 1.6; /* קובע את גובה השורה ל-1.6 מהגודל הרגיל */
  
  }
  }

 /* עיצוב הלוגו */
.mobile-logo {
  display: none; /* מוסתר במסכים גדולים */
  width: 150%; /* תופס את כל רוחב המסך */
  text-align: center; /* מרכז את התמונה בתוך האלמנט */
  position: fixed; /* מיקום קבוע בראש הדף */
  top: 0; /* מיקום הלוגו בצמוד לראש המסך */
  left: 0; /* מתחיל בצד שמאל של המסך */
  z-index: 1000; /* מבטיח שהלוגו יהיה מעל אלמנטים אחרים */
}

.mobile-logo img {
  max-width: 160px; /* רוחב מקסימלי לתמונה */
  height: auto; /* שמירת פרופורציות של התמונה */
  margin: 10px auto; /* ריווח מכל הצדדים */
  display: block; /* ממרכז את התמונה בתוך ה-div */
}

/* הצגת הלוגו במסכים קטנים בלבד */
@media (max-width: 768px) {
  .mobile-logo {
    display: block; /* הצגת הלוגו במסכים קטנים */
  }
}
 /* עיצוב הלוגו */
 .mobile-logo1 {
  display: none; /* מוסתר במסכים גדולים */
  width: 60%; /* תופס את כל רוחב המסך */
  text-align: center; /* מרכז את התמונה בתוך האלמנט */
  position: fixed; /* מיקום קבוע בראש הדף */
  top: 30px; /* מיקום הלוגו מעט למטה מ-0 */
  left: 0; /* מתחיל בצד שמאל של המסך */
  z-index: 1000; /* מבטיח שהלוגו יהיה מעל אלמנטים אחרים */
}

.mobile-logo1 img {
  max-width: 180px; /* רוחב מקסימלי לתמונה */
  height: auto; /* שמירת פרופורציות של התמונה */
  margin: 10px auto; /* ריווח מכל הצדדים */
  display: block; /* ממרכז את התמונה בתוך ה-div */
}

/* הצגת הלוגו במסכים קטנים בלבד */
@media (max-width: 768px) {
  .mobile-logo1 {
    display: block; /* הצגת הלוגו במסכים קטנים */
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  
  .mobile-logo {
    display: none; /* מוסתר במסכים גדולים */
    width: 150%; /* תופס את כל רוחב המסך */
    text-align: center; /* מרכז את התמונה בתוך האלמנט */
    position: fixed; /* מיקום קבוע בראש הדף */
    top: 0; /* מיקום הלוגו בצמוד לראש המסך */
    left: 0; /* מתחיל בצד שמאל של המסך */
    z-index: 1000; /* מבטיח שהלוגו יהיה מעל אלמנטים אחרים */
  }
  
  .mobile-logo img {
    max-width: 300px; /* רוחב מקסימלי לתמונה */
    height: auto; /* שמירת פרופורציות של התמונה */
    margin: 10px auto; /* ריווח מכל הצדדים */
    display: block; /* ממרכז את התמונה בתוך ה-div */
  }
    .mobile-logo {
      display: block; /* הצגת הלוגו במסכים קטנים */
    }


    .mobile-logo1 {
      display: none; /* מוסתר במסכים גדולים */
      width: 60%; /* תופס את כל רוחב המסך */
      text-align: center; /* מרכז את התמונה בתוך האלמנט */
      position: fixed; /* מיקום קבוע בראש הדף */
      top: 60px; /* מיקום הלוגו מעט למטה מ-0 */
      left: 0; /* מתחיל בצד שמאל של המסך */
      z-index: 1000; /* מבטיח שהלוגו יהיה מעל אלמנטים אחרים */
    }
    
    .mobile-logo1 img {
      max-width: 350px; /* רוחב מקסימלי לתמונה */
      height: auto; /* שמירת פרופורציות של התמונה */
      margin: 10px auto; /* ריווח מכל הצדדים */
      display: block; /* ממרכז את התמונה בתוך ה-div */
    }
  .mobile-logo1 {
    display: block; /* הצגת הלוגו במסכים קטנים */
  }
  }
